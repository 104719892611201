import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: auto;
    }
    .speech-bubble-mini{
      margin-top: -225px;
      margin-left: 92px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -170px;
      margin-left: 260px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 780px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -265px;
      margin-left: 360px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 374px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -130px;
      margin-left: 172px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: -130px;
      margin-left: 280px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: -130px;
      margin-left: 330px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 150px;
    height: auto;
    margin-top: 120px;
    margin-left: -131px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 260px;
    height: auto;
    margin-top: 418px;
    margin-left: -370px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 300px;
    height: auto;
    margin-top: 540px;
    margin-left: -370px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 200px;
    height: auto;
    margin-top: 190px;
    margin-left: -350px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    height: auto;
    margin-top: 270px;
    margin-left: -350px;
  }
  @media(min-width: 1920px){
    width: 350px;
    height: auto;
    margin-top: 355px;
    margin-left: -540px;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
